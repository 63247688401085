import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import P from 'prop-types';

import { BeatLoader } from 'react-spinners';

const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  height: 42px;
  min-width: 178px;
  max-width: max-content;

  @media screen and (max-width: 414px) {
    padding: 1px 20px;
    min-width: auto;
  }

  border-radius: 21px;

  border: 2px solid ${({ theme, color }) => theme.palette[color].main};

  background: ${({ theme, variant, color }) => {
    if (variant === 'outlined') return 'transparent';

    return theme.palette[color].main;
  }};

  &:hover {
    box-shadow: ${({ theme, color, disabled }) =>
      disabled ? 'none' : ` 0px 0px 4px ${theme.palette[color].main}`};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: ${({ theme, variant, color }) => {
    if (variant === 'outlined') return theme.palette[color].main;

    return theme.palette[color].text;
  }};
`;


export const Button = ({
  loading,
  label,
  color: colorProp,
  variant,
  children,
  disabled,
  ...props
}) => {
  const buttonRef = React.useRef();
  const theme = React.useContext(ThemeContext);
  const color = disabled ? 'disabled' : colorProp;

  if (loading) {
    const spinnerColor =
      variant === 'outlined' ? theme.palette[colorProp].main : theme.palette[colorProp].text;

    return (
      <Container color={color} variant={variant} {...props} onClick={() => {}}>
        <BeatLoader size={8} margin={4} color={spinnerColor} />
      </Container>
    );
  }

  return (
    <Container ref={buttonRef} color={color} variant={variant} disabled={disabled} {...props}>
        {children}
    </Container>
  );
};

Button.propTypes = {
  type: P.string,
  color: P.oneOf(['primary', 'blue', 'green', 'gray', 'purple']),
  variant: P.oneOf(['default', 'outlined']),
  disabled: P.bool,
  loading: P.bool,
};

Button.defaultProps = {
  type: 'submit',
  color: 'primary',
  variant: 'default',
  disabled: false,
  loading: false,
};
