import React from 'react';
import styled from 'styled-components';
import { Grid } from 'quod-ui';

const Container = styled(Grid)`
  height: 65px;
  background: #F7F7F7;
  border-bottom: 1px solid #c1c1c1;
`;

const Col = styled(Grid)`
  display: flex;
  align-items: center;

  height: 100%;
  padding: 0 32px;

  @media screen and (max-width:414px) {
  padding: 0 10px;
  }

  & + & {
    ${({ variant }) => (variant === 'bordered' ? 'border-left: 1px solid #D8D8D8' : '')};
  }
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
`;

export const Title = ({ config, variant }) => {
  return (
    <Container container>
      {config.map(item => (
        <Col key={item.title} size={item.size} variant={variant}>
          <Label>{item.title}</Label>
        </Col>
      ))}
    </Container>
  );
};
