import React, { Component} from 'react';
import {Redirect} from 'react-router-dom';
import { Loader, Space } from 'quod-ui';
import { Content } from 'components';
import { Store } from 'contexts';
import Cookies from 'universal-cookie';
import jwt from 'utils/jwt.js';

class Auth extends Component {
    static contextType = Store.Context;

    constructor(_history) {
        super();
        const {match} = _history;

        this.state = {
            loading: true,
            api_response: '', 
            user: null,
            match: match,
            token: '',
        };
    }
    
    componentWillMount() {

        const {hash, source} = this.state.match.params;
    
        let route = process.env.API_HOST + 
                    process.env.API_PATH_AUTH
                    .replace(/:hash/, hash)
                    .replace(/:source/, source);

        let status;

        fetch(route)
        .then(res => {
            status = res.status;
            return res.json();
        })
        .then(data => { 
            let statusNotAuthenticated = [404];
            let statusTechnicalProblem = [400, 500];
           
            if (statusNotAuthenticated.includes(status)) {
                this.setState({
                    ...this.state,
                    api_response: 'not-authenticated',
                    loading: false
                });
                return;
            }

            if (statusTechnicalProblem.includes(status)) {
                this.setState({
                    ...this.state,
                    api_response: 'technical-problem',
                    loading: false
                });
                return;
            }

            this.setState({
                ...this.state,
                api_response: 'success',
                loading: false,
                user: {
                    name: data.success.user.name,
                    email: data.success.user.email,
                    fullName: data.success.user.fullName,
                    authenticated: data.success.user.authenticated,
                },
                token: data.success.token,
                userJWT: data.success.userJWT
            });
        });
    }

    componentDidUpdate() {
        const context = this.context;
        const state = this.state;

        if (state.api_response !== 'success') {
            return true;
        }

        let name = state.user.name.split(' ');

        const newUser = {
            ...state.user,
            name: name[0],
            fullName: state.user.name,
            authenticated: true
        }        

        const minutes = 30;
        const options = {expires: new Date(Date.now() + (minutes*60*1000)), path: '/'};

        const c = new Cookies(); 
        c.set('user', state.userJWT, options);
        c.set('user-token', state.token, options);

        context.setStore({
            ...context,
            user: newUser
        });
        
        return true;
    }
    
    render() {

        switch(this.state.api_response) {
            case 'not-authenticated':
                return <Redirect to="/not-authenticated"/>;

            case 'technical-problem':
                return <Redirect to="/technical-problem"/>;

            case 'success':
                return <Redirect to="/ticket"/>
                
            default:
        }
        
        return (
            <>
                <Content>
                <Space top={20} />
                    <Loader loading={this.state.loading}>
                        <></>
                    </Loader>
                </Content>
            </>
        );
    }
}

export default Auth;