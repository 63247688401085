import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as Pages from 'pages';
import { Layout } from 'components';
import PrivateRoute from './PrivateRoute';

export default () => {

  return (
    <BrowserRouter>
      <Layout>
        <Switch>          
            <Route exact path="/" render={props => <PrivateRoute {...props} component={Pages.Home}/>} />
            <Route exact path="/auth/:hash/source/:source" component={Pages.Auth}/>
            <Route exact path="/ticket" render={props => <PrivateRoute {...props} component={Pages.ListTicket}/>} />
            <Route exact path="/ticket/new" render={props => <PrivateRoute {...props} component={Pages.NewTicket}/>} />
            <Route exact path="/ticket/new/:number" render={props => <PrivateRoute {...props} component={Pages.NewTicketSuccess}/>} />
            <Route exact path="/ticket/view/:number" render={props => <PrivateRoute {...props} component={Pages.ViewTicket}/>} />
            <Route exact path="/ticket/comment/:number/:sys_id" render={props => <PrivateRoute {...props} component={Pages.AddComment}/>} />
            <Route exact path="/ticket/comment/:number/:sys_id/success" render={props => <PrivateRoute {...props} component={Pages.AddCommentSuccess}/>} />
            <Route exact path="/ticket/reopen/:number/:sys_id" render={props => <PrivateRoute {...props} component={Pages.ReopenTicket} />}/>
            <Route exact path="/ticket/reopen/:number/:sys_id/success" render={props => <PrivateRoute {...props} component={Pages.ReopenTicketSuccess} />}/>
            <Route exact path="/ouvidoria" render={props => <PrivateRoute {...props} component={Pages.Ombudsman}/>} />
            <Route exact path="/not-authenticated" component={Pages.NotAuthenticated} />
            <Route exact path="/technical-problem" component={Pages.TechnicalProblem} />
            <Route component={Pages.NotFound} />    
        </Switch>      
      </Layout>
    </BrowserRouter>
  );
};
