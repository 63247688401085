import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Contentt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Photo = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background: #dddddc;
`;

const User = styled.label`
  font-weight: 800;
  font-size: 12px;
  line-height: 150%;
  color: #54585a;
`;

const Date = styled.label`
  font-weight: bold;
  font-size: 12px;
  color: #8f8f8f;
`;

export const ProfileColumn = (data) => {
  return (
    <Container>
      {/* <Photo /> */}
      <Contentt>
        <User>{data.author}</User>
        <Date>{data.date}</Date>
      </Contentt>
    </Container>
  );
};
