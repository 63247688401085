import jwt from 'jsonwebtoken';
import base64 from 'base-64';

export default {
    
    decode(token) {
        try {
            let key = this.getKey();
            if(token){
                return jwt.verify(token,key)
            }
            return {};
        } catch (e) {
            console.log("Erro no decode: ", e);
            return {};
        }
    },
    getKey(encodeBase64 = false) {
        return base64.decode(process.env.BASE64_PUBLICKEY);
    }
}