import React from 'react';
import { hooks } from 'quod-ui';
import Cookies from 'universal-cookie';
import jwt from 'utils/jwt.js';

const StoreContext = React.createContext({});

var initialStateUser = { authenticated: false };

const StoreProvider = ({ ...props }) => {

  const c = new Cookies(); 
  let userStorage = jwt.decode(c.get('user'));
  let userToken = c.get('user-token');
  let user = initialStateUser;

  if (typeof userStorage !== 'undefined') {
    user = userStorage;
  }
  
  const [state, setState] = hooks.useState({ 
    provider: process.env.APP_PROVIDER.toUpperCase(),
    user: user,
    userToken: userToken
  });
  
  return (
    <StoreContext.Provider
      value={{
        ...state,
        setStore: (...props) => setState(...props),
      }}
      {...props}
    />
  );
};

export const Store = {
  Context: StoreContext,
  Provider: StoreProvider,
  Consumer: StoreContext.Consumer,
};