import React from 'react';
import styled from 'styled-components';
import P from 'prop-types';

import { RowMobile } from './RowMobile';

const Container = styled.div`
  display: none;
  flex-direction: column;
  ${({ variant }) => (variant !== 'noBorder' ? 'border: 1px solid #D8D8D8' : '')};
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width:414px) {
    display: flex;
  }
`;

const addColumnsIntoRow = (data) => {
  let newData = [];
  let newItem = null;

  data.map(item => {
    
    newItem = { icon: 'open', ...item };
  
    if (item.state === 'Resolvido' || item.state === 'Encerrado') {
      newItem.icon = 'closed';
    }

    newData.push(newItem);
    return true;
  });  

  return newData;
};

export const TableMobile = ({ provider, config, config2, data, variant, onSelect }) => {
  let newData = addColumnsIntoRow(data);

  return (
    <Container variant={variant}>
      {newData.map(item => (
        <RowMobile
          provider={provider}
          icon={item.icon}
          key={JSON.stringify(item)}
          config={config}
          data={item}
          variant={variant}
          onSelect={onSelect}
        />
      ))}

      { typeof config2 !== 'undefined' &&
        newData.map(item => (
          <RowMobile
            provider={provider}
            icon={item.icon}
            key={JSON.stringify(item)}
            config={config2}
            data={item}
            variant={variant}
            onSelect={onSelect}
          />
        ))
      }
    </Container>
  );
};

TableMobile.propTypes = {
  onSelect: P.func,
  data: P.array,
  config: P.arrayOf(
    P.shape({
      title: P.string,
      size: P.shape({
        xs: P.number,
        sm: P.number,
        md: P.number,
        lg: P.number,
        xl: P.number,
      }),
      node: P.node,
      path: P.string,
    })
  ),
  variant: P.oneOf(['default', 'bordered', 'noBorder']),
};

TableMobile.defaultProps = {
  data: [],
  config: [],
  variant: 'default',
};
