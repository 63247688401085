import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Logo as QLogo, DropDown } from 'quod-ui';
import {Store} from 'contexts';
import Cookies from 'universal-cookie';
import jwt from 'utils/jwt.js';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 1366px;
  height: 68px;
  padding-top: 14px;
  padding-bottom: 16px;
  align-items: center;
`;

const LinkLogo = styled(Link)`
  padding-left: 5px;
  width: max-content;
`;

const Logo = styled(QLogo)``;

const Menu = styled(DropDown)`
  display: flex;
  width: 120px;
  margin-left: auto;
  font-size: 16px;
`;

export const HeaderQuod = (props) => {
  const context = React.useContext(Store.Context);
  
  const logout = () => {
    var user = { authenticated: false };

    const options = {path: '/'};
    
    const cookies = new Cookies(); 
    cookies.set('user', '', options);
    cookies.set('user-token', '', options);

    context.setStore({
        ...context,
        user: user
    });

    window.location.href=process.env.URL_LOGIN;
    return;
  }

  const name = `Olá ${props.user?.name}`;
  const menuItems = React.useMemo(() => {
    return [
      {
        label: 'Sair',
        onClick: logout,
      },
    ];
  }, []);

  return (
    <Container>
      <Content>

        <LinkLogo to="/">
          <Logo/>
        </LinkLogo>

        {
          props.user.authenticated
          ? <Menu label={name} items={menuItems}/>
          : null
        }        
      </Content>
    </Container>
  );
};
