import React from 'react';
import axios from 'axios';

import { hooks } from 'quod-ui';
import Cookies from 'universal-cookie';

export const baseURL = process.env.API_HOST;

const service = axios.create({
  baseURL: process.env.API_HOST,
  timeout: 60000,
});

// service.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error?.response?.data?.error?.status === 'NOT_AUTHORIZED') {
//       return (window.location.href = '/logout');
//     }

//     return Promise.reject(error);
//   }
// );

export default service;

export const useService = (type, route, data = {}, autoFetch = true, update) => {
  const provider = process.env.APP_PROVIDER;
  const c = new Cookies(); 
  const token = c.get('user-token');

  return hooks.useRequest(
    (params = {}, headers = {}) => {
      if (['get', 'options', 'head', 'delete'].includes(type)) {
        return service[type](route, {
          headers: { Authorization: token, provider, ...headers },
          params: autoFetch ? data : params,
        });
      }
      return service[type](route, autoFetch ? data : params, {
        headers: { Authorization: token, provider, ...headers },
      });
    },
    autoFetch,
    update
  );
};
