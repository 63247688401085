import React, { Component } from 'react';
import Message from './Message';
import { Header, Content } from 'components';

class NotAuthenticated extends Component {

    login() {
        window.location.href = process.env.URL_LOGIN; 
    }

    render() {
        return (
            <>
                <Header label="Comunicado"/>
                <Content>
                    <Message 
                        icon={require("../../assets/img/lock.png")}
                        title="Você entrou em uma área restrita"
                        subtitle="Por favor faça o login para acessar a página"
                        buttonText="Fazer login"
                        onClick={this.login}
                    />
                </Content>
            </>
        );
    }
};

export default NotAuthenticated;