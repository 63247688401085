import React from 'react';
import styled from 'styled-components';
import { Space } from 'quod-ui';

import { Header, Content as Container } from 'components';
import BlankButton from '../../components/Button/BlankButton';
import Message from './../auth/Message';

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 400px) {
      top: 15%;
      padding: 0 8%;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  margin-top: 48px;
`;

export default ({ history, match }) => {
  const handleOnBack = () => {
    history.push('/ticket');
  };

  return (
    <>
      <Header label="Central de Ajuda" />

      <Container>
        <Content>
          <Space top={3} />

          <Message 
              title="Página não encontrada"
              buttonText="Voltar para central de ajuda"
              onClick={handleOnBack}
          />

        </Content>
      </Container>
    </>
  );
};
