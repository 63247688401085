import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Grid } from './../Grid/Grid';
import { Icon } from './../Icon/Icon';
import ImgCollapseUp from './../../assets/svg/collapse_icon_up.svg';
import ImgCollapseDown from './../../assets/svg/collapse_icon_down.svg';

const Container = styled(Grid)`
  align-items: center;

  background: #fff;

  &:nth-child(odd) {
    background: #f7f7f7;
  }

  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'unset')};

  &:hover {
    ${({ hasAction }) => (hasAction ? 'background: #eee' : '')};
  }

  ${({ variant }) => (variant === 'bordered' ? 'border-top: 1px solid #D8D8D8' : '')};
`;

const Col = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: left;

  height: 100%;
  padding: 28px 32px;

  @media screen and (max-width:768px) {
    padding: 20px 10px;
  }

  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'unset')};

  & + & {
    ${({ variant }) => (variant === 'bordered' ? 'border-left: 1px solid #D8D8D8' : '')};
  }
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 19px;
  font-weight: normal;
`;

const Span = styled.span`
  font-weight: ${({icon}) => icon === 'closed' ? 'bold' : 'normal'};
`;

const SpanHidden = styled(Span)`
  display: ${({collapse}) => collapse ? 'flex' : 'none'};
  flex-direction: column;
  padding: 0 10px 28px 10px;
  width: 100%;
`;

const TitleHidden = styled.span`
  color: ${({provider}) => provider === 'QUOD' ? '#7850FF' : '#0AD1E9'};
  font-weight: bold;
  font-size: 12px;
  padding: 10px 0px;
`;

const DivHidden = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
`;

const LabelHidden = styled(Label)`
  font-weight: bold;
  margin-right:4px;
  width: 50%;
`;

const ValueHidden = styled.span`
  width: 50%;
`;

const CollapseIcon = styled.img`
  margin: 0px auto;
`;




export const RowMobile = ({ provider, icon, config, data, variant, onSelect }) => {
  
  const [collapse, setCollapse] = useState(false);

  const getValue = ({ path }) => {
    const type = {
      String: () => R.path(path.split('.'), data),
      Array: () => R.path(path, data),
      Function: () => path(data),
    };

    return type[R.type(path)]();
  };

  const setClickFunction = (item) => {
    return item.type === 'collapse_icon' ? setCollapse(!collapse) : onSelect(data);
  };

  const mountRowVisible = ({config, onSelect, variant, collapse, icon, provider}) => {
    let rows = [];
    config.map((item, index) => {

      if (item.mobile === true) {
        rows.push(
          <Col key={JSON.stringify(item)} size={item.mobileSize} variant={variant} 
            hasAction={!!onSelect} onClick={() => setClickFunction(item)}>
            
            { item.type === 'collapse_icon' ?
                <CollapseIcon src={collapse ? ImgCollapseDown : ImgCollapseUp} />                  
              : 
              <>
                <Label>{item.title}:</Label>
                <Span icon={icon}>
                  {index === 1 && <Icon mobile={true} provider={provider} icon={icon}/>}
                  {getValue(item)}
                </Span>
              </>
            }
          </Col>
        );
      }

      return true;      
    });

    return rows;
  }

  const mountRowHidden = ({config, onSelect, variant, collapse, icon, provider}) => {
    let rows = [];

    config.map(item => {
      if (item.mobile === false && item.type !== 'icon') {
        rows.push(
          <DivHidden onClick={() => setClickFunction(item)} key={JSON.stringify(item)}>
            <LabelHidden>{item.title}:</LabelHidden>
            <ValueHidden>{getValue(item)}</ValueHidden>
          </DivHidden>
        );
      }

      return true;
    });

    return rows;
  }

  return (
    <Container
      container
      variant={variant}>
        <>     
          {mountRowVisible({config, onSelect, variant, collapse, icon, provider})}

          <SpanHidden collapse={collapse}>
            
            <TitleHidden provider={provider}>
              Detalhes do Ticket:
            </TitleHidden>

            {mountRowHidden({config, onSelect, variant, collapse, icon, provider})}
          </SpanHidden>
        </>        
    </Container>
  );
};
