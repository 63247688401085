import React, {Component} from 'react';
import Message from './Message';
import { Header, Content } from 'components';

class TechnicalProblem extends Component {

    goBack() {
        window.location.href = "/ticket";
    }

    render() {
        return (
            <>
            <Header label="Comunicado" />
            <Content>
                <Message 
                    icon={require('./../../assets/img/alert.png')}
                    title="Estamos enfrentando problemas técnicos"
                    subtitle="Tente novamente em alguns instantes"
                    buttonText="Voltar"
                    onClick={this.goBack}
                />
            </Content>
            </>
        );
    }
}

export default TechnicalProblem;

