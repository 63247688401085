import React from 'react';
import styled from 'styled-components';
import ArrowControlCred from './../../assets/svg/controlcred/arrow.svg';
import ArrowQuod from './../../assets/svg/quod/arrow.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;

  border-bottom: 1px solid #c1c1c1;
`;

const Label = styled.span`
  font-size: 24px;
  font-weight: bold;

  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;

const Arrow = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const Span = styled.span`
  display: flex;
  flex-direction: row;
`;

const getSrcArrow = ({provider}) => {
    if (provider === 'QUOD') {
      return ArrowQuod;
    }
    return ArrowControlCred;
}

export const Title = ({ provider, label, backButton, onBackButtonClick, ticketNumber, children, ...props }) => {
  const src = getSrcArrow({provider});

  return (
    <Container {...props}>
      <Span>
        { backButton === true &&
          <Arrow provider={provider} src={src} onClick={() => onBackButtonClick(ticketNumber)}/>
        }
        <Label>{label}</Label>
      </Span>

      {children}
    </Container>
  );
};
