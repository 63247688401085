import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Space, Loader } from 'quod-ui';

import { Header, Content, Table, TableMobile, Title, Button as ButtonAux } from 'components';
import { useService } from 'service';

import { ProfileColumn } from './ProfileColumn';
import {Store} from 'contexts';

const getMessages = (data) => {
  const dates = data.match(/[\d]{2}\/[\d]{2}\/[\d]{4}\s[\d]{2}:[\d]{2}:[\d]{2}\s-/g);

  if (!dates) return [];

  let messages = data.split(/[\d]{2}\/[\d]{2}\/[\d]{4}\s[\d]{2}:[\d]{2}:[\d]{2}\s-/g);
  if (messages.length > 0 && messages[0] === '') {
    messages.shift();
  }

  return dates.map((date, index) => {
    const message = messages[index];
    const match = message.match(/(.*?)(\(Coment[^)]*\))([\w\W]*)*/);
    const author = R.trim(match[1]).split(' ').slice(0, 2).join(' ');

    return {
      date: date.replace('-', '').trim(),
      author,
      message: R.trim(match[3]),
    };
  });
};

export default ({ history, match }) => {
  const { provider, user } = React.useContext(Store.Context);
  const [data, { loading }] = useService('get', `/ticket/${match.params.number}`);

  const goListTicketPage = () => {
    history.push('/ticket');
  };

  const changePage = (number, sys_id, action) => {
    if (action === 'add-comment') {
      history.push(`/ticket/comment/${number}/${sys_id}`);
    }

    if (action === 'reopen-ticket') {
      history.push(`/ticket/reopen/${number}/${sys_id}`);
    }    
  }

  const detailsTable = [
    {
      title: 'Protocolo',
      size: { md: 2 },
      path: 'number',
      mobile: true,
      mobileSize: { md: 12 }
    },
    {
      title: 'Motivo',
      size: { md: 3 },
      path: 'category',
    },
    {
      title: 'Status',
      size: { md: 2 },
      path: 'state',
    },
    {
      title: 'Criado em',
      size: { md: 2 },
      path: 'sys_created_on',
    },
    {
      title: 'Ultima Atualização',
      size: { md: 3 },
      path: 'sys_updated_on',
    },
  ];

  const Button = styled(ButtonAux)`
    margin-left: 15px;
  `;

  const detailsTable2 = [
    {
      title: 'Motivo',
      size: { md: 6 },
      path: 'category',
      mobile: true,
      mobileSize: { md: 6 }
    },
    {
      title: 'Status',
      size: { md: 6 },
      path: 'state',
      mobile: true,
      mobileSize: { md: 6 }
    },
    {
      title: 'Criado em',
      size: { md: 6 },
      path: 'sys_created_on',
      mobile: true,
      mobileSize: { md: 6 }
    },
    {
      title: 'Ultima Atualização',
      size: { md: 6 },
      path: 'sys_updated_on',
      mobile: true,
      mobileSize: { md: 6 }
    },
  ];

  const descriptionTable = [
    {
      title: 'Descrição do Ticket',
      size: { md: 12 },
      path: 'description',
    },
  ];

  const logTable = [
    {
      title: 'Comentários',
      size: { md: 3 },
      path: ProfileColumn,
    },
    {
      title: '',
      size: { md: 9 },
      path: 'message',
    },
  ];

  const ticket = data?.success?.ticket || {};
  const messages = getMessages(ticket?.comments || '');

  return (
    <>
      <Header label="Central de Ajuda" variant="small" />

      <Content>

        <Loader loading={loading}>
          <Title 
            label={ticket.number ? `Ticket ${ticket.number}` : ''}
            backButton={true}
            onBackButtonClick={goListTicketPage}
            provider={provider}>
              
                { ticket.state === 'Resolvido' && ticket.category !== 'Autoatendimento' ? (
                  <Button onClick={() => changePage(ticket.number, ticket.sys_id, 'reopen-ticket')}>
                    Reabrir ticket
                  </Button>
                ) : ( 
                  ticket.state !== 'Encerrado' && ticket.state !== 'Resolvido' && ticket.category !== 'Autoatendimento' &&
                  <Button onClick={() => changePage(ticket.number, ticket.sys_id, 'add-comment')}>
                    Adicionar comentário
                  </Button>              
                )}
          </Title>

          <Space top={4} />

          <Table 
            config={detailsTable} 
            data={[ticket]} 
            hideMobile={true}
          />        

          <TableMobile
            config={detailsTable} 
            config2={detailsTable2} 
            data={[ticket]}
            provider={provider}
          />

          <Space top={4} />

          <Table config={descriptionTable} data={[ticket]} />

          {messages.length > 0 && (
            <>
              <Space top={4} />

              <Table config={logTable} data={messages} tableType={'comment'}/>
            </>
          )}
        </Loader>
      </Content>
    </>
  );
};
