import React from 'react';
import styled from 'styled-components';

import { Store } from 'contexts';

import { HeaderQuod } from './HeaderQuod';
import { HeaderControlcred } from './HeaderControlcred';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #666666;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #333333;
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border: 0px none #ffffff;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const Headers = {
  QUOD: HeaderQuod,
  CONTROLCRED: HeaderControlcred,
};

export const Layout = ({ children, ...props }) => {
  const { provider, user } = React.useContext(Store.Context);
  
  const Header = React.useMemo(() => {
    return Headers[provider];
  }, [provider]);

  return (
    <Container>
      <Header user={user}/>

      <Content>{children}</Content>
    </Container>
  );
};
