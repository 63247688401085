import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogoIMG from 'assets/img/controlcred.png';
import UserIMG from 'assets/img/user.png';
import LogoutIMG from 'assets/img/logout.png';
import {Store} from 'contexts';
import Cookies from 'universal-cookie';
import jwt from 'utils/jwt.js';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  background: #58595b;
  min-height: 43px;
  height: 43px;
`;

const LogoContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background: #c1c1c1;
  max-width: 200px;
  padding: 10px 16px;

  &:after {
    content: ' ';
    margin-right: -16px;
    border-left: 43px solid #c1c1c1;
    border-top: 43px solid #58595b;
  }
`;

const LinkLogo = styled(Link)``;

const Logo = styled.img`
  width: 100%;
`;

const DivPortalName = styled.div`
  display: flex;
  width: 50%;
  justify-content:flex-start;
  color: #c1c1c1;
  font-size: 15px;
  align-items: center;

  @media screen and (max-width: 414px) {
    display: none;
  }
`;

const DivImgUser = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;

const ImgUser = styled.img`
  height: 100%;
  width: auto;
`; 

const DivNameUser = styled.div`
  display: flex;
  width: 100px;
  color: #fff;
  font-size: 15px;
  align-self: center;

  @media screen and (max-width: 375px) {
    width: 50px;
  }

  @media screen and (max-width: 320px) {
    display: none;
  }
`; 

const ImgLogout = styled.img`
  height: 20px;
  width: auto;

  @media screen and (max-width: 414px) {
    margin-top:10px;
  }
`; 

const DivLogout = styled.div`
  @media screen and (max-width: 414px) {
    display: none;
  }
`; 


const ExitContent = styled.div`
  padding: 2px;
  height: 100%;
  width: 43px;
  background: #c1c1c1;
  font-size: 16px;
  text-align: center;
  color: #fff;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

export const HeaderControlcred = (props) => {
  const context = React.useContext(Store.Context);
 
  const logout = () => {
    var user = { authenticated: false };

    const options = {path: '/'};
    
    const cookies = new Cookies(); 
    cookies.set('user', '', options);
    cookies.set('user-token', '', options);

    context.setStore({
        ...context,
        user: user
    });

    window.location.href=process.env.URL_LOGIN;
    return;
  }

  

  const name = `Olá ${props.user.name}`;
  
  return (
    <Container>

      <LogoContent>
        <LinkLogo to="/">
          <Logo src={LogoIMG}/>
        </LinkLogo>
      </LogoContent>

      {
        props.user.authenticated
        ? 
          <>
            <DivPortalName>
              Portal do Consumidor
            </DivPortalName>

            <DivImgUser>
              <ImgUser src={UserIMG}/>
              <DivNameUser>{ name }</DivNameUser>
            </DivImgUser>

            <ExitContent onClick={logout}>
              <ImgLogout src={LogoutIMG}/>
              <DivLogout>sair</DivLogout>
            </ExitContent>
          </>
        : null
      } 
    </Container>
  );
};

