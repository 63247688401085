import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Grid } from 'quod-ui';
import { Icon } from './../Icon/Icon';

const Container = styled(Grid)`
  align-items: center;
  background: #fff;

  &:nth-child(odd) {
    background: #f7f7f7;
  }

  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'unset')};

  &:hover {
    ${({ hasAction }) => (hasAction ? 'background: #eee' : '')};
  }

  ${({ variant }) => (variant === 'bordered' ? 'border-top: 1px solid #D8D8D8' : '')};
`;

const Col = styled(Grid)`
  display: flex;
  align-items: center;
  font-weight: ${({icon}) => icon === 'closed' ? 'bold' : 'none'};

  min-height: 65px;
  padding: 10px 32px;

  @media screen and (max-width:768px) {
    padding: ${({tableType}) => tableType === 'comment' ? '0px' : '28px'} 10px;
  }

  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'unset')};

  & + & {
    ${({ variant }) => (variant === 'bordered' ? 'border-left: 1px solid #D8D8D8' : '')};
  }
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 19px;
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'unset')};
`;

export const Row = ({ provider, icon, config, data, variant, onSelect, tableType }) => {
  const getValue = ({ path }) => {
    const type = {
      String: () => R.path(path.split('.'), data),
      Array: () => R.path(path, data),
      Function: () => path(data)
    };

    return type[R.type(path)]();
  };
  
  const mountRows = ({config, variant, onSelect, provider, icon, tableType}) => {
    let rows = [];
    
    config.map(item => {
      if (item?.type !== 'collapse_icon') {
        rows.push(
          <Col icon={icon} tableType={tableType}
            key={JSON.stringify(item)} size={item.size} variant={variant} hasAction={!!onSelect}>
            
            { item?.type === 'icon' ?
              <Icon provider={provider} icon={icon}/>
              : <Label as={item.node} hasAction={!!onSelect}>{getValue(item)}</Label>
            }
          </Col>
        );
      }

      return true;
    });
  
    return rows;
  }

  return (
    <Container
      container
      variant={variant}
      onClick={() => onSelect && onSelect(data)}
      hasAction={!!onSelect}>
      {mountRows({config, variant, onSelect, provider, icon, tableType})}
    </Container>
  );
};
