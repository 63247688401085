import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Space, hooks } from 'quod-ui';
import {Button} from './../Button/Button';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const FileName = styled.span`
  align-self: center;
  color: #7e8188;
  font-size: 14px;
`;

export const InputFile = ({ value: valueProps, error, onChange, schema, ...props }) => {
  const refFile = React.useRef();
  const [{ value }, setState] = hooks.useState({});

  React.useEffect(() => {
    if (valueProps && valueProps !== value) setState({ value: valueProps });
    // eslint-disable-next-line
  }, [valueProps]);

  const handleOnChange = (e) => {
    onChange(e, e.target.files[0]);
    setState({ value: e.target.files[0] });
    
    refFile.current.value = null;
  };

  return (
    <>
      <Container>
        <Space bottom={1}>
          São aceitos arquivos png, jpg e pdf.
        </Space>
      </Container>
      <Container>
        <Space left={1} right={2}>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => refFile.current.click()}>
            adicionar arquivo
          </Button>
        </Space>

        {/* <FileName>{R.path(['name'], value)}</FileName> */}

        <input ref={refFile}
          name={schema}
          type="file"
          hidden
          onChange={handleOnChange}
          accept={props.accept ?? 'image/*'} />
      </Container>
    </>
  );
};
