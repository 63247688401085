import React from 'react';
import styled from 'styled-components';
import P from 'prop-types';

const Container = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

const Content = styled.div`
  ${({ theme, size, mobileSize }) => {

    if (typeof mobileSize !== 'undefined') {
      size = mobileSize;
    }

    return (`
      width: 100%;

      ${['xs', 'sm', 'md', 'lg', 'xl']
        .map(key => `max-width:${(100 / 12) * size[key]}%`)
        .join(';')}
    `)
  }}
`;

export const Grid = ({ container, children, ...props }) => {
  return container ? (
    <Container {...props}>{children}</Container>
  ) : (
    <Content {...props}>{children}</Content>
  );
};

Grid.defaultProps = {
  container: false,
  size: { md: 12 },
};

Grid.propTypes = {
  container: P.bool,
  size: P.shape({
    xs: P.number,
    sm: P.number,
    md: P.number,
    lg: P.number,
    xl: P.number,
  }),
};
