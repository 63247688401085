import React from 'react';
import styled from 'styled-components';

const BlankButton = styled.button`
/* Btn/Ativo Alt */ 
/* Auto Layout */

margin-top: 20px;
padding: 8px 36px;
background: #FFFFFF;

/* Roxo Quod */

border: 2px solid ${process.env.APP_PROVIDER.toLowerCase() === 'quod' ? '#7850FF;' : '#0AD1E9;'}
box-sizing: border-box;
border-radius: 99px;

font-family: Halcom;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 17px;
/* identical to box height */

/* Roxo Quod */
color: ${
    process.env.APP_PROVIDER.toLowerCase() === 'quod' ? '#7850FF;' : '#0AD1E9;'
}

:hover {
    box-shadow: 0px 0px 4px ${process.env.APP_PROVIDER.toLowerCase() === 'quod' ? '#7850FF;' : '#0AD1E9;'}
    cursor: pointer;
}

:focus {
    outline: none;
}
`;

export default (props) => {   
    return (
        <>
            <BlankButton 
             onClick={() => props.onClick ? props.onClick() : null }
            >{props.buttonText}</BlankButton>
        </>
    );
};