import React from 'react';
import styled from 'styled-components';
import P from 'prop-types';

import { Title } from './Title';
import { Row } from './Row';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ variant }) => (variant !== 'noBorder' ? 'border: 1px solid #D8D8D8' : '')};
  border-radius: 8px;
  overflow: hidden;

  ${({hideMobile}) => (`
    @media screen and (max-width:414px) {
      ${hideMobile === true ? 'display: none;' : ''}
    }
  `)}
`;

const addColumnIntoRow = (data) => {
  let newData = [];
  let newItem = null;

  data.map(item => {
    
    newItem = { icon: 'open', ...item };
  
    if (item.state === 'Resolvido' || item.state === 'Encerrado') {
      newItem.icon = 'closed';
    }
    newData.push(newItem);

    return true;
  });  
  return newData;
};

export const Table = ({ tableType, provider, config, data, variant, onSelect, hideMobile }) => {
  let newData = addColumnIntoRow(data);

  return (
    <Container variant={variant} hideMobile={hideMobile}>
      <Title config={config} variant={variant} />

      {newData.map(item => (
        <Row
          provider={provider}
          icon={item.icon}
          key={JSON.stringify(item)}
          config={config}
          data={item}
          variant={variant}
          onSelect={onSelect}
          tableType={tableType}
        />
      ))}
    </Container>
  );
};

Table.propTypes = {
  onSelect: P.func,
  data: P.array,
  config: P.arrayOf(
    P.shape({
      title: P.string,
      size: P.shape({
        xs: P.number,
        sm: P.number,
        md: P.number,
        lg: P.number,
        xl: P.number,
      }),
      node: P.node,
      path: P.string,
    })
  ),
  variant: P.oneOf(['default', 'bordered', 'noBorder']),
};

Table.defaultProps = {
  data: [],
  config: [],
  variant: 'default',
};
