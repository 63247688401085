import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Store } from 'contexts';

class PrivateRoute extends Component {
    static contextType = Store.Context;
  
    constructor(props) {
      super(props);
      
      this.state = {
        component: props.component,
        user: null,
        userToken: null
      };
    }

    componentWillMount() {
      const context = this.context;
     
      this.setState({
        user: context.user,
        userToken: context.userToken,
      });  
    }
  
    render() {
      const user = this.state.user;
      
      if (!user?.authenticated) {
          return <Redirect to={{pathname: '/not-authenticated'}} />;
      }

      return (
        <Route {...this.props}/>
      );
    }
}
export default PrivateRoute;