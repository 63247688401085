import React from 'react';

import { Store } from 'contexts';

import { Quod } from './Quod';
import { Controlcred } from './Controlcred';

const HEADERS = {
  QUOD: Quod,
  CONTROLCRED: Controlcred,
};

export const Header = ({ ...props }) => {
  const { provider } = React.useContext(Store.Context);

  const Component = React.useMemo(() => HEADERS[provider], [provider]);

  return <Component {...props} />;
};
