import React from 'react';
import styled from 'styled-components';
import { Button, FormGrid, TextInput, TextArea, Space, Select, Loader, hooks } from 'quod-ui';
import moment from 'moment';

import { Header, Content, Title, InputFile } from 'components';
import { useService } from 'service';
import { Store } from 'contexts';

import RemoveImg from 'assets/img/remove.png';

const Form = styled(FormGrid)`
  padding-top: 48px;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Error = styled.span`
  width: 100%;
  padding-top: 2px;
  padding-left: 8px;
  text-align: left;
  color: #931B1D;
  font-size: 14px;
  line-height: 17px;
`;

const SpaceList = styled(Space)`
  width: 100%;
`;

const FileList = styled.li`
  padding-top: 2px;
  padding-left: 8px;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
`

const RemoveButton = styled.img`
  margin-left: 3px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const getVariant = (type) => {
  const TYPES = {
    date: {
      variant: 'date',
    },
    timedate: {
      variant: 'timedate',
    },
    number: {
      variant: 'numeric',
      maskConfig: {
        maxlength: 20,
      },
    },
    cnpj: {
      variant: 'cnpj',
    },
    money: {
      variant: 'money',
    },
    text: {},
  };

  return TYPES[type] || {};
};

const validations = {
  category: [[(value) => {
    if (typeof value === 'string') value = value.trim();
    return !value;
  }, 'Campo obrigatório']],
  description: [[(value) => {
    if (typeof value === 'string') value = value.trim();
    return !value;
  }, 'Campo obrigatório']],
};

export default ({ history }) => {
  const [customValidations, setValidations] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const [categories, { loading: loadingCategories }] = useService('get', '/category');
  const [, { loading: loadingCreate }, createTicket] = useService('put', '/ticket', {}, false);

  const [form, onChange] = hooks.useForm({ validations: { ...validations, ...customValidations } });
  
  const { user } = React.useContext(Store.Context);

  const handleOnBack = () => {
    history.push('/ticket');
  };

  const handleOnSubmit = async (e) => {
    const formData = new FormData();

    formData.append('category', form.getValue('category')?.value);
    formData.append('description', form.getValue('description'));
    
    files.map(file => formData.append('files[]', file));
    files.map(file => formData.append('names[]', file.name));

    const result = await createTicket(formData, {
      'Content-Type': 'multipart/form-data',
    });

    if (result?.error?.status === 'ERROR') {
      setErrorMessage(result.error.ticket?.errorMessage || 'Erro desconhecido');
      setTimeout(() => setErrorMessage(''), 4000);
      return
    }

    if (result?.success?.status === 'OK') {
      history.push(`/ticket/new/${result.success.ticket.CasoNumero}`);
    }
  };

  const removeFile = (item) => {
    let index = files.map(file => file.name).indexOf(item.name);

    if (index === -1) return;

    let newFiles = [...files];
    newFiles.splice(index, 1);
    
    setFiles(newFiles);
  };
  
  const formConfig = [
    [
      {
        schema: 'category',
        size: { md: 12 },
        node: {
          type: Select,
          props: ({ schema }) => ({
            label: 'Motivo da Solicitação*',
            data: categories?.success?.categories || [],
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: (e, value) => onChange(schema)(value),
          }),
        },
      },
    ],
    [
      {
        schema: 'description',
        size: { md: 12 },
        node: {
          type: TextArea,
          props: ({ schema }) => ({
            label: 'Descrição*',
            value: form.getValue(schema) || '',
            error: form.getError(schema),
            onChange: (e, value) => onChange(schema)(value),
          }),
        },
      },
    ],
    [
      {
        schema: 'file',
        size: { md: 12 },
        node: {
          type: InputFile,
          props: ({ schema }) => ({
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: (e, value) => {
              if (value === undefined) return;
              let allowedFiles = ['image/png', 'image/jpeg', 'application/pdf'];

              if (allowedFiles.indexOf(value.type) === -1) {
                setErrorMessage('Tipo do arquivo inválido');
                setTimeout(() => setErrorMessage(''), 4000);
                return;
              }

              let currentFiles = files ?? [];
              currentFiles.push(value);
              setFiles(currentFiles);

              onChange(schema)(value);
            },
            accept: '.png, .jpg, .jpeg, .pdf',
          }),
        },
      },
    ],
  ];

  return (
    <>
      <Header label="Novo ticket" variant="small" />

      <Content>
        <Title label="Novo ticket">
          <Button onClick={handleOnBack}>Voltar</Button>
        </Title>

        <Loader loading={loadingCategories}>
          <Form config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
            <SpaceList>
              <ul>
                {
                  files.map((item, index) => (
                    <FileList key={index}>
                      { item.name }
                      <RemoveButton src={RemoveImg} onClick={ () => removeFile(item) }></RemoveButton>
                    </FileList>
                  ))
                }
              </ul>
            </SpaceList>
            {
              errorMessage ? 
                <Space>
                  <Error>{ errorMessage }</Error>
                </Space> :
                ''
            }
            <Space top={3}>
              <Button loading={loadingCreate}>Salvar</Button>
            </Space>
          </Form>
        </Loader>
      </Content>
    </>
  );
};
