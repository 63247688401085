import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header, Content, Title, Button, FormGrid, InputFile } from 'components';
import { Space, Loader, TextArea, hooks } from 'quod-ui';
import { useService } from 'service';

import { Store } from 'contexts'

import RemoveImg from 'assets/img/remove.png';

const validations = {
  description: [[(value) => {
    if (typeof value === 'string') value = value.trim();
    return !value;
  }, 'Campo obrigatório']]
};

  const Form = styled(FormGrid)`
  padding-top: 48px;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Error = styled.span`
  width: 100%;
  padding-top: 2px;
  padding-left: 8px;
  text-align: left;
  color: #931B1D;
  font-size: 14px;
  line-height: 17px;
`;

const SpaceList = styled(Space)`
  width: 100%;
`;

const FileList = styled.li`
  padding-top: 2px;
  padding-left: 8px;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
`

const RemoveButton = styled.img`
  margin-left: 3px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export default ({history, match}) => {
    const {number, sys_id} = match.params;
    const [files, setFiles] = React.useState([]);
    const [customValidations, setValidations] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState('');
    const [form, onChange] = hooks.useForm({ validations: { ...validations, ...customValidations } });
    const [, {loading: loadingCreate}, addCommentReopen] = useService('put', `/ticket/commentReopen`, {}, false);
    const [, {loading: loadingReopen}, reopenTicket] = useService('put', `/ticket/reopen`, {}, false);

    const { user } = React.useContext(Store.Context);

    const handleOnBack = () => {
        history.push('/ticket');
    };

    const goViewSuccessPage = () => {
      history.push(`/ticket/reopen/${number}/${sys_id}/success`);
    }

    const reopen = async (e) => {
        const formData = new FormData();
        
        formData.append('description', form.getValue('description'));
    
        files.map(file => formData.append('files[]', file));
        files.map(file => formData.append('names[]', file.name));
        formData.append('sys_id', sys_id);
        
        const result = await reopenTicket(formData, {
          'Content-Type': 'multipart/form-data',
        });
        
        if (result?.error?.status === 'ERROR') {
            console.log(`Error: ${result.error.status}`);
        }

        if (result?.success?.status === 'OK') {
            goViewSuccessPage();
        }
    }

    const handleOnSubmit = async (e) => {
      const formData = new FormData();
      
      formData.append('ticket', number);
      formData.append('sys_id', sys_id);
      formData.append('client', user.client);
      formData.append('comment', form.getValue('description'));
      //formData.append('state', 'Reaberto');

      files.map(file => formData.append('files[]', file));
      files.map(file => formData.append('names[]', file.name));
      
      const result = await addCommentReopen(formData, {
        'Content-Type': 'multipart/form-data', 
      });
      

      if (result?.error?.status === 'ERROR') {
        setErrorMessage(result.error.ticket?.errorMessage || 'Erro desconhecido');
        setTimeout(() => setErrorMessage(''), 4000);
        return
      }

      if (result?.success?.status === 'OK') {
        goViewSuccessPage(number);
      }
    }

    const removeFile = (item) => {
        let index = files.map(file => file.name).indexOf(item.name);
    
        if (index === -1) return;
    
        let newFiles = [...files];
        newFiles.splice(index, 1);
        
        setFiles(newFiles);
    };

    const formConfig = [
        [
          {
            schema: 'description',
            size: { md: 12 },
            node: {
              type: TextArea,
              props: ({ schema }) => ({
                label: 'Descrição*',
                value: form.getValue(schema) || '',
                error: form.getError(schema),
                onChange: (e, value) => onChange(schema)(value),
              }),
            },
          },
        ],
        [
          {
            schema: 'file',
            size: { md: 12 },
            node: {
              type: InputFile,
              props: ({ schema }) => ({
                value: form.getValue(schema),
                error: form.getError(schema),
                onChange: (e, value) => {
                  if (value === undefined) return;
                  let allowedFiles = ['image/png', 'image/jpeg', 'application/pdf'];
    
                  if (allowedFiles.indexOf(value.type) === -1) {
                    setErrorMessage('Tipo do arquivo inválido');
                    setTimeout(() => setErrorMessage(''), 4000);
                    return;
                  }
    
                  let currentFiles = files ?? [];
                  currentFiles.push(value);
                  setFiles(currentFiles);
    
                  onChange(schema)(value);
                },
                accept: '.png, .jpg, .jpeg, .pdf',
              }),
            },
          },
        ],
    ];

    // useEffect(() => {
    //     reopen();
    // }, []);
   

    return(
        <>
            <Header label="Central de ajuda" variant="small" />
            <Content>
                <Title label="Reabrir ticket">
                <Button onClick={handleOnBack}>Voltar</Button>
                </Title>
              <Loader loading={false}>
                <Form config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
                    <SpaceList>
                    <ul>
                        {
                        files.map((item, index) => (
                            <FileList key={index}>
                            { item.name }
                            <RemoveButton src={RemoveImg} onClick={ () => removeFile(item) }></RemoveButton>
                            </FileList>
                        ))
                        }
                    </ul>
                    </SpaceList>
                    {
                    errorMessage ? 
                        <Space>
                          <Error>{ errorMessage }</Error>
                        </Space> :
                        ''
                    }
                    <Space top={3}>
                      <Button loading={loadingCreate}>Salvar</Button>
                    </Space>
                </Form>
              </Loader>
            </Content>
        </>
    );
}

