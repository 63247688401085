import React from 'react';
import { Provider } from 'quod-ui';

import { Store } from 'contexts';

import Routes from './Routes';

export default () => {
  return (
    <Store.Provider>
      <Provider source={process.env.APP_PROVIDER}>
        <Routes />
      </Provider>
    </Store.Provider>
  );
};
