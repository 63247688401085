import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: ${({ variant }) => (variant === 'small' ? 80 : 160)}px;
  background: #f7f7f7;

  @media screen and (max-width: 400px) {
    height: 80px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
  max-width: 1366px;
  height: 100%;
`;

const Label = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.purple.main};
  font-size: 36px;
  padding: 15px 0px;

  @media screen and (max-width: 414px) {
    font-size: 25px;
  }

  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
`;

export const Quod = ({ label, variant, ...props }) => {
  return (
    <Container variant={variant}>
      <Content>
        <Label>{label}</Label>
      </Content>
    </Container>
  );
};
