import React from 'react';
import styled from 'styled-components';
import BlankButton from './../../components/Button/BlankButton';

const Div = styled.div`
    position: relative;
    top:20%;
    text-align: center;

    @media screen and (max-width: 400px) {
        top:15%;
        padding: 0 10%;
    }
`;

const H1 = styled.h1`
    font-size: 24px;
`;

const Span = styled.span`
    font-size: 16px;
    display: block;
`;


export default (props) => {
    return (
        <Div>
            {props.icon ? <img src={props.icon} alt="Lock icon" /> : null }
            <H1>{props.title}</H1>
            {props.subtitle ? <Span>{props.subtitle}</Span> : null }            
            {props.buttonText ? <BlankButton {...props} /> : null }  
        </Div>
    );  
};