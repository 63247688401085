import React from 'react';
import styled from 'styled-components';
import { Space } from 'quod-ui';

import { Header, Content as Container } from 'components';
import BlankButton from '../../components/Button/BlankButton';
import IconSuccess from './../../assets/img/success.png';

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 400px) {
      top: 15%;
      padding: 0 8%;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  margin-top: 48px;
`;

export default ({ history, match }) => {
  const handleOnBack = () => {
    history.push('/ticket');
  };

  return (
    <>
      <Header label="Novo Ticket" variant="small" />

      <Container>
        <Content>
          <Space top={3} />
          
          <img src={IconSuccess} alt="Success Icon"/>

          <Title>
            Seu ticket foi enviado e gerou o protocolo <b>{match.params.number}</b>.
            <br />
            Iremos analisar e retornaremos em breve.
          </Title>

          <BlankButton 
            buttonText="Voltar para central de ajuda"
            onClick={handleOnBack}
          />
        </Content>
      </Container>
    </>
  );
};
