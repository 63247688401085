import React from 'react';
import { Button, Loader, Space } from 'quod-ui';

import { Header, Content, Title, Table, TableMobile } from 'components';
import { useService } from 'service';
import Message from './../auth/Message';
import { Store } from 'contexts';

export default ({ history }) => {
  const { provider } = React.useContext(Store.Context);
  const [tickets, { loading }] = useService('get', `/ticket`);

  const handleOnNew = () => {
    history.push('/ticket/new');
  };

  const handleOnView = (item) => {
    history.push(`/ticket/view/${parseInt(item.number)}`);
  };

  const tableConfig = [
    {
      title: '',
      type: 'icon',
      size: { sm: 1 },
      path: 'icon',
      mobile: false
    },
    {
      title: 'Nº do Pedido',
      size: { md: 2 },
      path: 'number',
      mobile: true,
      mobileSize: { md: 6 }
    },
    {
      title: 'Motivo da solicitação',
      size: { md: 3 },
      path: 'category',
      mobile: false
    },
    {
      title: 'Criado em',
      size: { md: 2 },
      path: 'created_on',
      mobile: false
    },
    {
      title: 'Status',
      size: { md: 2 },
      path: 'state',
      mobile: true,
      mobileSize: { md: 5 }
    },
    {
      title: 'Últ. atualização',
      size: { md: 2 },
      path: 'updated_on',
      mobile: false
    },
  ];

  const tableConfigMobile = [
    ...tableConfig,
    {
      title: '',
      type: 'collapse_icon',
      size: { sm: 2 },
      path: 'collapse_icon',
      mobile: true,
      mobileSize: { md: 1 }
    }
  ];

  return (
    <>
      <Header label="Central de Ajuda" />

      <Content>
        <Title label="Meus tickets">
          <Button onClick={handleOnNew}> Abrir um novo ticket</Button>
        </Title>

        <Space top={3} />

        <Loader loading={loading}>
          <>
            {
              tickets?.success?.tickets ?
              <>
                <Table
                  provider={provider}
                  config={tableConfig}
                  data={tickets?.success?.tickets}
                  onSelect={handleOnView}
                  hideMobile={true}
                />

                <TableMobile
                  provider={provider}
                  config={tableConfigMobile}
                  data={tickets?.success?.tickets}
                  onSelect={handleOnView}
                />
              </>
                
              :
                <Message 
                    icon={require("./../../assets/img/folder.png")}
                    subtitle="Você não tem nenhum ticket em aberto no momento"
                />
              }
            </>
        </Loader>
      </Content>
    </>
  );
};
