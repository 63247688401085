import React from 'react';
import styled from 'styled-components';
import P from 'prop-types';

import { Grid } from '../Grid/Grid';

const Container = styled.form`
  width: 100%;
  margin-top: 16px;
`;

const Content = styled(Grid)``;

const Item = styled(Grid)`
  margin-top: 16px;
`;

const Column = ({ size, node, schema, hide }, key) => {
  const show = hide ? !hide() : true;

  if (!show) return null;

  const props = node?.props({ schema });

  const Component = React.createElement(node?.type, { ...props, name: schema });

  return (
    <Item key={key} size={size}>
      {Component}
    </Item>
  );
};

export const FormGrid = ({ config, children, ...props }) => {
  return (
    <Container {...props}>
      {config.map((row, key) => {
        return (
          <Content key={key} container>
            {row.map(Column)}
          </Content>
        );
      })}

      {children}
    </Container>
  );
};

FormGrid.defaultProps = {
  autoComplete: 'none',
};

FormGrid.propTypes = {
  autoComplete: P.string,
  onSubmit: P.func,
  config: P.array.isRequired,
};
