import React from 'react';
import styled from 'styled-components';
import { Button, FormGrid, TextInput, TextArea, Space, Modal, hooks } from 'quod-ui';

import { Header, Content, InputFile } from 'components';
import { useService } from 'service';

const Description = styled.div`
  padding-top: 24px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Form = styled(FormGrid)`
  padding-top: 24px;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 32px;
  color: #7e8188;

  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export default () => {
  const [, { loading: loadingGetInfo }, fetchProtocol] = useService(
    'post',
    '/ombudsman/info',
    {},
    false
  );
  const [, { loading: loadingSaveProtocol }, fetchSaveProtocol] = useService(
    'put',
    '/ombudsman',
    {},
    false
  );

  const [form, onChange] = hooks.useForm({
    initialValues: { showTicketForm: false },
    validations: {},
  });

  const handleCheckProtocol = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const result = await fetchProtocol({
      protocol: form.getValue('protocol'),
    });

    if (result?.error?.status === 'ERROR') {
      onChange('showTicketForm')(false);
      return form.setError(result.error);
    }

    if (result?.success?.status === 'OK') {
      onChange('showTicketForm')(true);
    }
  };

  const handleOnSubmit = async () => {
    const bodyFormData = new FormData();

    bodyFormData.append('protocol', form.getValue('protocol'));
    bodyFormData.append('description', form.getValue('description'));

    form.getValue('file') && bodyFormData.append('file', form.getValue('file'));

    const result = await fetchSaveProtocol(bodyFormData, {
      'Content-Type': 'multipart/form-data',
    });

    if (result?.error?.status === 'ERROR') {
      return form.setError(result.error);
    }

    if (result?.success?.status === 'OK') {
      form.setValues({
        protocol: null,
        description: null,
        file: null,
        showTicketForm: false,
        newProtocol: result.success.protocol,
      });
      // setModal(result.result.number);
    }
  };

  const formProtocol = [
    [
      {
        schema: 'protocol',
        size: { md: 12 },
        node: {
          type: TextInput,
          props: ({ schema }) => ({
            label: 'Protocolo Reclamado',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: (e, value) =>
              form.setValues({
                [schema]: value,
                description: null,
                file: null,
                showTicketForm: false,
                newProtocol: null,
              }),
          }),
        },
      },
    ],
  ];

  const formTicket = [
    [
      {
        schema: 'description',
        size: { md: 12 },
        node: {
          type: TextArea,
          props: ({ schema }) => ({
            label: 'Descrição',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: (e, value) => onChange(schema)(value),
          }),
        },
      },
    ],
    [
      {
        schema: 'file',
        size: { md: 12 },
        node: {
          type: InputFile,
          props: ({ schema }) => ({
            label: 'Anexo',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: (e, value) => onChange(schema)(value),
          }),
        },
      },
    ],
  ];

  return (
    <>
      <Header label="Ouvidoria" />

      <Content>
        <Description>
          <Text bold>
            Se você chegou até aqui é porque ainda tem algum problema que está te incomodando.
          </Text>
          <Text>
            A Ouvidoria controlCred está aqui para representar os interesses de nossos clientes e
            consumidores, mediando a solução dos conflitos de forma imparcial, transparente e
            eficiente. Nossa atuação está baseada nas normas de Direito do Consumidor e demais
            regulamentações aplicáveis.
          </Text>
          <Text>
            Para registrar uma demanda na Ouvidoria ControlCred, informe o número do protocolo de
            atendimento anterior.
          </Text>
          <Text bold>
            Caso não tenha, selecionar a opção "Central de ajuda" e depois selecionar "Solicitar
            ajuda" para obter o protocolo válido.
          </Text>
        </Description>

        <Form config={formProtocol} onSubmit={handleCheckProtocol}>
          <Space top={3}>
            <Button loading={loadingGetInfo}>CONTINUAR</Button>
          </Space>
        </Form>

        {form.values.showTicketForm && (
          <Form config={formTicket} onSubmit={form.trySave(handleOnSubmit)}>
            <Space top={3}>
              <Button loading={loadingSaveProtocol}>ENVIAR</Button>
            </Space>
          </Form>
        )}

        <Modal
          title="Sua demanda foi enviada com sucesso!"
          onClose={() => onChange('newProtocol')(null)}
          visible={form.values.newProtocol}
          size={{ md: 4, sm: 8, xs: 10 }}>
          <Text>
            Protocolo de Ouvidoria nº <strong>{form.values.newProtocol}</strong>
          </Text>

          <Text>
            O prazo para análise e resposta da Ouvidoria é de até 10 dias uteis (conforme Lei nº
            12.414/2011 e Decreto nº 7829/2012).
          </Text>

          <Text>Agradecemos sua contribuição</Text>
        </Modal>
      </Content>
    </>
  );
};
