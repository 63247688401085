import React from 'react';
import styled from 'styled-components';
import iconClosedQuod from './../../assets/svg/quod/elipse_closed.svg';
import iconClosedControlCred from './../../assets/svg/controlcred/elipse_closed.svg';
import iconOpen from './../../assets/svg/elipse_open.svg';

const Img = styled.img`
  margin: 0px 3px;
  ${({mobile}) => mobile !== true && `margin: 0px auto;`}
`;

export const Icon = ({icon, mobile, provider}) => {
    const IconClosed = {
        QUOD: iconClosedQuod,
        CONTROLCRED: iconClosedControlCred,
    };

    const getIcon = (icon) => {
        if (icon === 'open') {
            return iconOpen; 
        }

        return IconClosed[provider];
    };

    return (
        <Img mobile={mobile} src={getIcon(icon)}/>
    );
}