import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 32px 5%;
`;

export const Content = ({ ...props }) => {
  return <Container {...props} />;
};
